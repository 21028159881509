<template>
  <picture
    v-if="src"
    :class="{ 'hidden' : !exibirMobile}"
  >
    <source
      :data-srcset="image.src"
      :type="`image/${image.fileType}`">
    <img
      :style=" {maxWidth : `${actualSize}px`}"
      :title="label"
      :alt="label"
      :src="image.src"
      :class="{lazyload: lazyload}"
      :width="width"
      :height="height"
    >
  </picture>
</template>

<script>
export default {
  name: 'ImagemCompomnent',
  props: {
    src: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    lazyload: {
      type: Boolean,
      default: true,
    },
    exibirMobile: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
    actualSize: {
      type: String,
      default: null,
    },
  },
  computed: {
    image() {
      const fileType = this.src.split('.').pop();
      let src = null;
      try {
        // eslint-disable-next-line import/no-dynamic-require, global-require
        src = require(`@/assets/img${this.src}`);
      } catch (error) {
        console.error(error);
      }
      return {
        src,
        fileType,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  picture {
    position: relative;

    img {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: 992px) {
    .hidden {
      display: none!important;
    }
  }
</style>
