<template>
  <div class="navbar" :class="{ 'small': small }">
    <imagem
      src="/pi.png"
      class="img-fluid logo"
      width="512"
      height="512"
      actual-size="75"
      label="Logo"
    />
      <div>
        <h2>iPi<span>Games</span></h2>
        <h1>Um <strong>jogo competitivo</strong> de matemática</h1>
        <h3>Rumo a chegada do aprendizado</h3>
    </div>
  </div>
</template>

<script lang="ts">
import Imagem from '../Imagem.vue';

export default {
  name: 'NavbarComponent',
  components: {
    Imagem,
  },
  props: {
    small: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../assets/scss/_global.scss';
  .navbar {
      @include flex;
      @include paper;
      padding: 20px 60px;
      position: relative;
      top: 0;
      left: 0;
  }
  .small {
      flex-direction: row;
      padding: 10px 50px;
      text-align: left;
      h1, h3 {
          text-align: left;
          font-size: .7em;
      }
      h2 {
          text-align: left;
          font-size: 1.5em;
          margin-bottom: 8px;
      }
      .logo {
          margin-left: 0;
          margin-right: 50px;
      }
  }
  .logo {
      max-width: 100px;
      margin: 16px auto;
  }
  h2 {
      font-size: 2em;
      margin-bottom: 16px;
      color: $primary-blue;
      text-align: center;
  }
  span {
      color: $primary-red;
      font-family: 'Montserrat' !important;
  }
  h1, h3 {
      font-size: 1em;
      font-weight: normal;
      text-align: center;
  }
  .text-left {
      text-align: left;
  }
</style>
