import Vue from 'vue';
import Vuex from 'vuex';
import game from './game';
import player from './player';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    game,
    player,
  },
});
