import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const isAuthorized = (to, from, next) => {
  if (store.getters['player/name']) {
    next();
  } else {
    next('/');
  }
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
  },
  {
    path: '/lobby/:roomId?',
    name: 'lobby',
    component: () => import('@/views/LobbyView.vue'),
  },
  {
    path: '/play',
    name: 'play',
    component: () => import('@/views/PlayView.vue'),
    beforeEnter: isAuthorized,
  },
  {
    path: '/end',
    name: 'end',
    component: () => import('@/views/EndView.vue'),
    beforeEnter: isAuthorized,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

export default router;
