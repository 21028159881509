import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faMapMarkerAlt, faHeart, faUser, faCircleCheck, faClock,
} from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import '@/assets/scss/_global.scss';

library.add(faMapMarkerAlt, faHeart, faHeartRegular, faUser, faCircleCheck, faClock);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
