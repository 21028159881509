<template>
  <div class="appframe">
    <Navbar />
    <div class="container content">
      <slot></slot>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/skeleton/Navbar.vue';
import Footer from '@/components/skeleton/Footer.vue';

export default {
  name: 'BaseLayout',
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style lang="scss" scoped></style>
