<template>
  <v-app>
    <v-main>
      <component :is="layout">
        <router-view :layout.sync="layout" />
      </component>
    </v-main>
  </v-app>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout.vue';

export default {
  name: 'App',
  data() {
    return {
      layout: BaseLayout,
    };
  },
};
</script>
