// import ipigamesCore from '@/services/ipigames-core';

const data = {
  player: {
    name: '',
    profile: 'player',
  },
};

const getters = {
  name: (state) => state.player.name,
  profile: (state) => state.player.profile,
};

const mutations = {
  setName(state, payload) {
    state.player.name = payload;
  },
  setProfile(state, payload) {
    state.player.profile = payload;
  },
};

const actions = {
  async createPlayer({ commit }, payload) {
    // await ipigamesCore.post('player', { playerName: payload });
    commit('setName', payload);
  },
  createProfile({ commit }, payload) {
    commit('setProfile', payload);
  },
};

export default {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};
