<template>
  <footer class="footer">
    <p>© Copyright</p>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
};
</script>

<style lang="scss" scoped>
  @import '../../assets/scss/_global.scss';

  footer {
    position: relative;
    bottom: 0;
    width: 100vw;
    padding: 20px 40px;
    background-color: white;
    @include flex;
    @include paper;
  }
</style>
