import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=d2650f1e&scoped=true&"
import script from "./Navbar.vue?vue&type=script&lang=ts&"
export * from "./Navbar.vue?vue&type=script&lang=ts&"
import style0 from "./Navbar.vue?vue&type=style&index=0&id=d2650f1e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2650f1e",
  null
  
)

export default component.exports