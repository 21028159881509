// import ipigamesCore from '@/services/ipigames-core';

const data = {
  game: {
    gameId: '',
    numberPlayers: 0,
    difficulty: '',
    numberQuestions: 0,
    winner: '',
  },
};

const getters = {
  gameId: (state) => state.game.gameId,
  numberPlayers: (state) => state.game.numberPlayers,
  difficulty: (state) => state.game.difficulty,
  numberQuestions: (state) => state.game.numberQuestions,
  numberWinners: (state) => state.game.numberWinners,
};

const mutations = {
  setGameId(state, payload) {
    state.game.gameId = payload;
  },
  setNumberPlayers(state, payload) {
    state.game.numberPlayers = payload;
  },
  setDifficulty(state, payload) {
    state.game.difficulty = payload;
  },
  setNumberQuestions(state, payload) {
    state.game.umberQuestions = payload;
  },
  setWinner(state, payload) {
    state.game.winner = payload;
  },
};

const actions = {
  async createGame({ commit }, payload) {
    // const date = new Date().toJSON();
    // await ipigamesCore.post('game', { gameTime: date }).catch((error) => {
    //   console.error(error);
    // });
    if (payload?.id) {
      commit('setGameId', payload.id);
    } else {
      commit('setGameId', payload);
    }
  },
  fixedNumberPlayers({ commit }, payload) {
    commit('setNumberPlayers', payload);
  },
  fixedDifficulty({ commit }, payload) {
    commit('setDifficulty', payload);
  },
  fixedNumberQuestions({ commit }, payload) {
    commit('setNumberQuestions', payload);
  },
  fixedNumberWinners({ commit }, payload) {
    commit('setNumberWinners', payload);
  },
};

export default {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};
